.site-nav {
  height: 80px;
  background: $dark;
  color: $white;
}
.site-nav__container {
  @extend .container;
  display: flex;
  align-items: center;
  height: 100%;
}
.site-nav__logo {
  object-fit: contain;
  object-position: center;
  height: 50px;
  // padding: 10px 0;
}
.site-nav__links {
  display: none;
  margin: 0;
  list-style-type: none;
  margin-left: auto; // push navbar to the right
}
.site-nav__link {
  &:not(:first-child) {
    margin-left: 2.5rem;
  }
  a {
    color: $white;
    text-decoration: none;
    font-family: 'aeonik-light';
    position: relative;
    padding: 0.25rem 0.5rem;
    &:after {
      content: '';
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background: $cyan;
      transition: all 0.3s;
    }
    &:hover {
      color: $white;
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }
  }
}

.site-nav__offcanvas-links {
  @extend .site-nav__links;
  display: flex;
}
.site-nav__offcanvas-link {
  @extend .site-nav__link;
  margin-left: 0;
  &:not(:first-child) {
    margin-left: 0;
  }
  margin-bottom: 1.5rem;
  text-align: center;
  a {
    display: inline-block;
    width: auto;
    color: $dark;
    padding: 0.5rem 1.5rem;
    font-size: 24px;
    &:hover {
      color: $dark;
    }
  }
}

.site_nav__menu-toggle {
  font-size: 24px;
  margin-left: auto;
  cursor: pointer;
}

.offcanvas-end {
  border-left: 0;
}

.offcanvas-backdrop::before {
  animation: fadeIn ease 0.3s;
}

.offcanvas-body {
  padding: 0;
}

@include media-breakpoint-up(lg) {
  .site-nav__links {
    display: flex;
  }
  .site-nav__link {
    &:not(:first-child) {
      margin-left: 2.5rem;
    }
  }
}

.site-nav__offcanvas-sublinks {
  transform: scaleY(0);
  height: 0;
  transform-origin: top;
  transition: transform 0.26s ease;
  overflow-y: hidden;
  transition: all 300ms;
  background-color: $dark;
  &.expanded {
    height: auto;
    transform: scaleY(1);
    transition: transform 0.26s ease;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.site-nav__offcanvas-sublink {
  @extend .site-nav__offcanvas-link;
  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.site-nav__sublink {
  @extend .site-nav__link;
  &:not(:first-child) {
    margin-left: 0;
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
