

.hero-wall {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns content to the bottom */
  min-height: 500px;
  background-color: $dark;
  color: $white;
  padding: 2rem 1rem 0.5rem 1rem; /* Reduced bottom padding */
  background-position: top;
  background-size: cover;
  transition: all 300s;
  background-repeat: no-repeat;
}


@include media-breakpoint-up(lg) {
  .hero-wall {
    padding: 4rem;
  }
}
