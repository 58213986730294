.home-hero-container {
  position: relative;
  min-height: calc(100vh - 80px);
}

.home-hero-footer {
  position: relative;

  .bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20%;

    @include media-breakpoint-up(sm) {
      width: 25%;
    }
  }

  .top-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 15%;

    @include media-breakpoint-up(sm) {
      width: 20%;
    }
  }
}

.nick-black-title {

  color: #fff;
  font-size: 100px;
  line-height: 0.8;

  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  span {
    font-size: 1.1em;
  }

  @include media-breakpoint-up(sm) {
    margin-left: 10%;
    font-size: 130px;
    color: #fff;
  }

  @include media-breakpoint-up(md) {
    margin-left: 0;
    font-size: 100px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 110px;
    color: #000;
  }
  
}

.nick-black-subtitle {
  
  @include media-breakpoint-up(sm) {
    color: #fff;
  }
  @include media-breakpoint-up(xl) {
    color: #000;
  }

}

// @include media-breakpoint-down(lg) {
//   .nick-black-title {
//     font-size: 56px;
//   }
// }
.home-hero-img {
  max-height: calc(100vh - 80px - 3rem);
}

.input-border {
  box-shadow: 0px 0px 0px 3px $purple;
}
.home-hero-bg-right-block {
  transform: rotate(30deg) scale(0.5);
}

.home-hero-bg-left-block {
  transform: scaleX(0.5) translateX(450px);
}

.video-description {
  position: relative;
  padding: 2.5rem;
  z-index: 2;
  width: 100%;
}
@include media-breakpoint-up(lg) {
  .video-description {
    max-width: 550px;
    hyphens: auto;
  }
}
@include media-breakpoint-down(sm) {
  .video-description {
    padding: 1.5rem;
  }
}
@include media-breakpoint-down(md) {
  .video-background {
    min-height: 300px;
  }
}

.video-description-content {
  position: relative;
  z-index: 3;
}

.video-description-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.home-event-background {
  height: 75vh;
  background-position: center;
  background-size: cover;
}
@include media-breakpoint-up(xl) {
  .home-event-background {
    height: 90vh;
  }
}

.video-play-btn {
  font-size: 128px;
  cursor: pointer;
  transition: all 300ms;
  &:hover {
    transform: scale(1.1);
  }
}

.push-right {
  margin-left: 10%;
}

.push-left {
  margin-right: 10%;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.bottom-white,
.top-white {
  height: 5vh;
}

.video-backdrop {
  background: rgba(0, 0, 0, 0.85);
}

@include media-breakpoint-up(lg) {
  .next-section-btn {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    color: $white;
    transition: all 300ms;
    &:hover {
      transform: scale(1.1, 1.1);
    }
    z-index: 5;
  }
  .next-section-tooltip {
    font-size: 12.5px;
    transition: all 300ms;
    padding-top: 5px;
  }
  .next-section-background {
    margin-top: 0.5rem;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    cursor: pointer;
    transition: all 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
