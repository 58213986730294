@function generateValues($initialValue, $end, $step, $unit) {
  $value: $initialValue;
  $arguments: ();

  @while $value <= $end {
    $arguments: map-merge(
      $arguments,
      (
        '#{$value}': #{$value}#{$unit},
      )
    );
    $value: $value + $step;
  }

  @return $arguments;
}
