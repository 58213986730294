.talk-card {
  background-color: $light;
  margin-bottom: 2rem;
}
.talk-play-btn {
  font-size: 64px;
  transition: all 300ms;
  cursor: pointer;
  color: $white;
  &:hover {
    font-size: 78px;
  }
}

.markdown-body.talk-description {
  font-family: 'aeonik-light' !important;
  font-size: 18px !important;
}

.talk-date {
  color: $white;
}

.day {
  font-size: 76px;
  line-height: 0.85;
}
.month-year {
  text-transform: uppercase;
  font-size: 20px;
}
.time {
  font-size: 16px;
}
.weekday {
  font-size: 18px;
}

.talk-workshop {
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    content: '';
    width: 200%;
    height: 200%;
    background-image: url('/assets/images/NB_Framework2.png');
    opacity: 0.1;
    transform: rotate(-65deg) translateY(100px);
    z-index: 2;
    background-size: 200px;
    background-repeat: repeat;
  }
  & > * {
    position: relative;
    z-index: 3;
  }
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.2);
}
.thumbnail-container {
  position: relative;
}
.up-next {
  display: none;
}
.up-next.active {
  display: block;
  position: absolute;
  top: 0;
  width: calc(100% - 27px);
  z-index: 5;
  height: auto;
  text-align: center;
  color: $white;
  background: $purple;
}
.archived-talks-header {
  background: $dark;
  margin-bottom: 2rem;
  margin-top: 6rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  // text-align: center;
  color: $white;
}
