@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/utilities';

$cyan: #1ca6b2;
$green: #00b17b;
$purple: #6e3cc6;
$highlight-purple: #ba37f3;
$white: #ffffff;
$light: #f0f0f0;
$dark: #272726;
$highlight: #baffbd;
$success: #00B07A;
$link: #ba37f3;
$link_hover: #1ca6b2;

$theme-colors: (
  'primary': $cyan,
  'light': $light,
  'dark': $dark,
  'white': $white,
  'cyan': $cyan,
  'green': $green,
  'purple': $purple,
  'highlight': $highlight,
  'highlight-purple': $highlight-purple,
);

$screen-ss: 260px;
$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0px,
  ss: $screen-ss,
  // Small screen / phone
  sm: $screen-sm,
  // Medium screen / tablet
  md: $screen-md,
  // Large screen / desktop
  lg: $screen-lg,
  // Extra large screen / wide desktop
  xl: $screen-xl
);

$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.5,
);

$input-border-radius: 0;
$input-border-width: 0;
$input-focus-box-shadow: 0px 0px 0px 3px $cyan;
$btn-border-radius: 0;

$utilities: map-merge(
  $utilities,
  (
    'position': (
      property: 'position',
      values: (
        'relative': relative,
        'absolute': absolute,
        'fixed': fixed,
      ),
      class: 'pos',
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'width': (
      property: width,
      class: w,
      responsive: true,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
      ),
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'delay': (
      property: 'animation-delay',
      values: generateValues(0, 5000, 50, 'ms'),
      class: 'delay',
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'duration': (
      property: 'animation-duration',
      values: generateValues(0, 5000, 50, 'ms'),
      class: 'duration',
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'color': (
      property: color,
      class: text,
      responsive: true,
      values:
        map-merge(
          $theme-colors,
          (
            'primary': $cyan,
            'light': $light,
            'dark': $dark,
            'white': $white,
            'cyan': $cyan,
            'green': $green,
            'purple': $purple,
            'highlight': $highlight,
            'gray-300': $gray-300,
            'gray-600': $gray-600,
            'gray-700': $gray-700,
            'gray-800': $gray-800,
            'gray-900': $gray-900,
          )
        ),
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'background-color': (
      property: background-color,
      class: bg,
      responsive: true,
      values:
        map-merge(
          $theme-colors,
          (
            'primary': $cyan,
            'light': $light,
            'dark': $dark,
            'white': $white,
            'cyan': $cyan,
            'green': $green,
            'purple': $purple,
            'highlight': $highlight,
            'gray-300': $gray-300,
            'gray-600': $gray-600,
            'gray-900': $gray-900,
          )
        ),
    ),
  )
);

@import 'bootstrap/scss/bootstrap';

.modal-content {
  border-radius: 0;
  border: 0;
}
.modal.fade .modal-dialog {
  transform: unset;
}
