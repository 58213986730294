h1 {
  font-size: 64px;
}
h2 {
  font-size: 48px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
.lead {
  font-size: 24px;
}

.text-regular {
  font-family: 'aeonik';
}
.text-thin {
  font-family: 'aeonik-light';
}
.text-bold {
  font-family: 'aeonik-bold';
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
  }
}
