.banner {

    background-image: url(/assets/images/banner-bg.svg);
    background-repeat: no-repeat;
    background-position: bottom -27px right -27px;
    background-size: contain;
    background-origin: content-box;

    @include media-breakpoint-down(xl){
        background-size: 25%;
    }
    @include media-breakpoint-down(lg){
        background-image: none;
    }
    
    &__img {
        max-width: 250px;
        margin-right: 8%;
        @include media-breakpoint-down(lg){
            max-width: 230px;
        }
    }

    &__title {
        max-width: 350px;
    }

}