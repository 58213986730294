$font-path: "../fonts/";

@font-face {
    font-family: 'aeonik';
    src: url('#{$font-path}Aeonik-Regular.woff');
    src: url('#{$font-path}Aeonik-Regular.woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aeonik-italic';
    src: url('#{$font-path}Aeonik-RegularItalic.woff');
    src: url('#{$font-path}Aeonik-RegularItalic.woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aeonik-light';
    src: url('#{$font-path}Aeonik-Light.woff');
    src: url('#{$font-path}Aeonik-Light.woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aeonik-light-italic';
    src: url('#{$font-path}Aeonik-LightItalic.woff');
    src: url('#{$font-path}Aeonik-LightItalic.woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aeonik-bold';
    src: url('#{$font-path}Aeonik-Bold.woff');
    src: url('#{$font-path}Aeonik-Bold.woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aeonik-bold-italic';
    src: url('#{$font-path}Aeonik-BoldItalic.woff');
    src: url('#{$font-path}Aeonik-BoldItalic.woff2');
    font-weight: normal;
    font-style: normal;
}