.coaching-section-icon {
  height: 250px;
  width: 100%;
  background: $dark;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    object-fit: contain;
    height: 250px;
    width: 100%;
    padding: 2rem;
  }
}

.connector {
  height: 100%;
  width: 3px;
  background: $gray-300;
}

.connector-dot {
  width: 12px;
  height: 12px;
  background: $cyan;
  border-radius: 50%;
}
