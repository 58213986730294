// @mixin button-variant(
//   $background,
//   $border,
//   $color: color-contrast($background),
//   $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
//   $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
//   $hover-color: color-contrast($hover-background),
//   $active-background: if($color == $color-contrast-light, shade-color($background,$btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
//   $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
//   $active-color: color-contrast($active-background),
//   $disabled-background: $background,
//   $disabled-border: $border,
//   $disabled-color: color-contrast($disabled-background)
// )

.btn-primary {
  @include button-variant(
    $cyan,
    $cyan,
    $white,
    lighten($cyan, 5%),
    lighten($cyan, 5%),
    $white,
    darken($cyan, 10%),
    darken($cyan, 10%),
    $white,
    $cyan,
    $cyan,
    $dark
  );
}

.btn-success {
  @include button-variant(
    $success,
    $success,
    $white,
    lighten($success, 5%),
    lighten($success, 5%),
    $white,
    darken($success, 10%),
    darken($success, 10%),
    $white,
    $success,
    $success,
    $dark
  );
}

.btn:focus,
.btn-close:focus {
  outline: none;
  box-shadow: none;
}

.btn:disabled,
.btn.disabled,
a.btn:disabled,
a.btn.disabled {
  opacity: 1;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
}
